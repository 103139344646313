<template>
  <div class="createAccount container-fluid min-vh-100 whitebg">
    <vue-headful
      title="Install-HC - Créer mon compte"
      description="Page permettant de devenir membre du site Install-HC"
    />
    <div>
      <nav role="navigation">
        <div id=header class="float-right">
          <InstallHC/>
        </div> 
        <Menu/>
      </nav>
    </div>
    <div class="inputForm">
      <h1>Création d'un compte</h1>            
      <form class="shadow" id="creationAccount" @submit="checkForm" method="post" >

      <p v-if="errors.length">
        <b>Merci de corriger les erreurs suivantes :</b>
        <ul>
          <li v-for="error in errors" v-bind:key="error.id">{{ error.message }}</li>
        </ul>
      </p>

      <p v-if="creation == true">
        <ul>
          <li class="blue">Compte créé avec succès !</li>
          <li class="blue">Merci de vous authentifier.</li>
        </ul>
      </p>

      <p>
        <label for="login">Login désiré</label>&nbsp;
        <input id="login" v-model="login" type="text" name="login" pattern="[A-Za-z0-9\\_\\-\\s\\.]{3,}" title="Merci d'utiliser des caractères autorisés !" :disabled="disabled == 1">
      </p>

      <p>
        <label for="passwd">Mot de passe</label>&nbsp;
        <input id="passwd" v-model="passwd" type="password" name="passwd" pattern=".{6,}" title="Minimum 6 caractères" :disabled="disabled == 1">
      </p>

      <p>
        <label for="passwd2">Vérification du mot de passe</label>&nbsp;
        <input id="passwd2" v-model="passwd2" type="password" name="passwd2" pattern=".{6,}" title="Minimum 6 caractères" :disabled="disabled == 1">
      </p>

      <p>
        <label for="email">Adresse e-mail</label>&nbsp;
        <input id="email" v-model="email" type="email" name="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" title="Merci de saisir une adresse e-mail valide !" :disabled="disabled == 1">
      </p>

      <p>
        <label for="email2">Vérification de l'adresse e-mail</label>&nbsp;
        <input id="email2" v-model="email2" type="email" name="email2" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" title="Merci de saisir une adresse e-mail valide !" :disabled="disabled == 1">
      </p>

      <p>
        <label for="check">Ecrire <b>{{ json }}</b> à l'envers</label>&nbsp;
        <input id="check" v-model="check" type="text" name="check" pattern=".{6}" title="Merci de saisir les 6 caractères. La casse n'a pas d'importance." :disabled="disabled == 1">
      </p>

      <p>
        <input type="submit" value="Valider" :disabled="disabled == 1">
      </p>

      <p>
        <router-link to="/login">Se connecter</router-link>
      </p>
      </form>
    </div>    
  </div>  
</template>

<script>
// @ is an alias to /src
import Menu from '@/components/Menu.vue'
import InstallHC from '@/components/InstallHC.vue'
import axios from 'axios'
export default { 
  name: 'createAccount',
  components: {
    Menu,
    InstallHC
  },
  data() {         
    return {
      json: null,                 
      login: null,
      passwd: null,
      passwd2: null,
      check: null,
      email: null,
      email2: null,
      creation: "false",
      errors: [],
      disabled: 0
    }    
  },  
  methods:{
    //handleClick(e) {
    //  if (e.target.matches('.play-video, .play-video *')) {
    //    console.log('Got a click on .play-video or a child element')
    //  }
    //},
    forceRerender: function() {
      setTimeout(() => {
        location.reload(true)  
      }, 500);
      this.$router.push('/login/')
    },
    checkForm: function (e) {
      var apiUrl = "/backend/get/form/createAccount"
      this.errors = [];

      if (!this.login) {
        this.errors.push('Login requis !');
      }
      if (!this.passwd) {
        this.errors.push('Mot de passe requis !');
      }

       if (this.passwd2!=this.passwd) {
        this.errors.push('Mots de passe différents !');
      }

      if (!this.email) {
        this.errors.push('Adresse e-mail requise !');
      }

      if (this.email!=this.email2) {
        this.errors.push('E-mails différents !');
      }

      if (!this.check) {
        this.errors.push('Code de sécurité requis !');
      }

      if (this.login && this.passwd && this.passwd && this.email && this.check) {        
        fetch(apiUrl,{
          method: "POST",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({login: this.login, passwd: this.passwd, email: this.email, check: this.check})
        })
        .then(res => res.json())
        .then(res => {
          this.creation = res.creation
          if (res.error) {
            this.errors = res.error;
          } else {            
            this.disabled=1
            setTimeout(() => {
              this.forceRerender()
            }, 1800); 
          }
        });
      }
      e.preventDefault();
    }
  },
  created() {  
    axios.get(this.$protocolServer + '://' + this.$urlServer + '/backend/get/form/checkAccess').then(response => {this.json = response.data.response;
    })      
  }   
}

</script>

<style scoped>
.debug {
  display: none;
}

.createAccount {
  margin:0 auto;  
  padding:0;
}

h1 {
  font-size:1.05em;
  font-weight:bold;
  text-align: center;
  margin-top:10px;
}

a {
  font-weight: bold;
  font-size:1.1rem;
}

a:hover
{
  text-decoration:none;  
}

ul {
  list-style:none;
  color:red;
  font-weight: bold;
  padding-left:5px;
}

#creationAccount {
  text-align:left;
  margin-left:20px;
  padding:10px;
  border-radius:5px;
  min-width:100px;    
  max-width:300px;
}

label {
  width:200px;
}

.blue {
  color: #007bff;
}

</style>